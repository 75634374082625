import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import MyChatBot from "./chatbot/chatbot";
import { useFilesFromSandbox } from "./utils";
import { Code } from "./editor/code";
import { Type, File, Directory, findFileByName } from "./utils/file-manager";
import "./App.css";
import logo from './assets/logo.png'; // Tell webpack this JS file uses this image
import { Stack, Typography } from "@mui/material";
import { FileTree } from "./editor/file-tree";

const CURRENT_SANDBOX_ID = "c932gk";

const dummyDir: Directory = {
  id: "1",
  name: "loading...",
  type: Type.DUMMY,
  parentId: undefined,
  depth: 0,
  dirs: [],
  files: []
};


const App = () => {
  const [rootDir, setRootDir] = useState(dummyDir);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  useFilesFromSandbox(CURRENT_SANDBOX_ID, (root) => {
    if (!selectedFile) {
      setSelectedFile(findFileByName(root, "README.md"));
    }
    setRootDir(root);
  });

  const onSelect = (file: File) => {
    setSelectedFile(file)
  };

  return (
    <Stack spacing={0.4} useFlexGap={true}>
       <Grid
        container
        spacing={1}
        display="flex"
        columns={{xs: 4, sm: 8, md: 12}}
        justifyContent="center"
        alignItems="center"
        >
        <Grid><a href="https://technology-robot.com/" target="_blank" rel="noreferrer noopener"><img src={logo} alt="TR Logo" width={50}/></a></Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        display="flex"
        columns={{xs: 4, sm: 8, md: 12}}
        direction="row-reverse"
        justifyContent="center"
        alignItems="stretch"
        >
        <Grid xs={4} sm={2.5} md={2.5}>
          <MyChatBot
            steps={[
              {
                id: '1',
                message: "Please enter the respository to chat with...",
                trigger: '3'
              },
              {
                id: '3',
                user: true,
                trigger: '4'
              },
              {
                id: '4',
                message: 'Hi {previousValue}, nice to meet you!',
                trigger: '2'
              },
              {
                id: '2',
                component: (
                  <div>
                    Made with love by
                    <br />
                    <a color="#fff" href="https://technology-robot.com">https://technology-robot.com</a> ❤️
                  </div>
                ),
                end: true,
              },
          ]}/>
        </Grid>
        <Grid xs={4} sm={8} md={8}>
          <Code selectedFile={selectedFile} />
        </Grid>
        <Grid xs={4} sm={1.5} md={1.5}>
          <FileTree
            rootDir={rootDir}
            selectedFile={selectedFile}
            onSelect={onSelect}
            />
        </Grid>
      </Grid>
      <a href="https://technology-robot.com/#:~:text=%F0%9F%92%AC-,Aisha%20for%20code,-Search%20and%20retrieve" target="_blank" rel="noreferrer noopener"><Typography align="right">More about Aisha</Typography></a>
    </Stack>
  );
};

export default App;
